document.addEventListener('DOMContentLoaded', () => {

  
    const fatboxLinks = document.querySelectorAll('a[data-fatbox]');
    const fatboxGroups = {};
    let imagePos;
    let startX;

    if (fatboxLinks.length > 0) {
        groupImages();
    }

    // Group images by their data-fatbox attribute
    function groupImages() {
        fatboxLinks.forEach(link => {
            const group = link.getAttribute('data-fatbox');
            if (!fatboxGroups[group]) {
                fatboxGroups[group] = [];
            }
            fatboxGroups[group].push(link.getAttribute('href'));

            link.addEventListener('click', event => {
                event.preventDefault();
                openFatbox(link);
            });
        });
    }

    // Open the fatbox for the clicked image
    function openFatbox(link) {
        if (!document.querySelector('.fatbox')) {
            createFatbox();
            addControls();
        }

        const imageUrl = link.getAttribute('href');
        document.querySelector('.fatbox img').src = imageUrl;

        const group = link.getAttribute('data-fatbox');
        imagePos = fatboxGroups[group].indexOf(imageUrl);
    }

    // Create the fatbox structure
    function createFatbox() {
        const fatboxElement = document.createElement('div');
        fatboxElement.className = 'fatbox';

        const fatboxContainer = document.createElement('div');
        fatboxContainer.className = 'fatbox-container';

        const img = document.createElement('img');
        fatboxContainer.appendChild(img);

        const prevButton = document.createElement('div');
        prevButton.className = 'fatbox-prev';
        prevButton.textContent = '';
        fatboxContainer.appendChild(prevButton);

        const nextButton = document.createElement('div');
        nextButton.className = 'fatbox-next';
        nextButton.textContent = '';
        fatboxContainer.appendChild(nextButton);

        const closeButton = document.createElement('div');
        closeButton.className = 'fatbox-close';
        closeButton.textContent = '';
        fatboxContainer.appendChild(closeButton);

        fatboxElement.appendChild(fatboxContainer);
        document.body.appendChild(fatboxElement);
    }

    // Add control event listeners
    function addControls() {
        document.querySelector('.fatbox-close').addEventListener('click', closeFatbox);
        document.querySelector('.fatbox-prev').addEventListener('click', () => navigateImage(-1));
        document.querySelector('.fatbox-next').addEventListener('click', () => navigateImage(1));

        document.addEventListener('keydown', handleKeydown);

        // Add touch event listeners for swipe functionality
        const fatboxContainer = document.querySelector('.fatbox-container');
        fatboxContainer.addEventListener('touchstart', handleTouchStart);
        fatboxContainer.addEventListener('touchend', handleTouchEnd);
    }

    // Handle keyboard navigation and close
    function handleKeydown(event) {
        if (event.key === 'Escape' || event.key === 'Esc') {
            closeFatbox();
        } else if (event.key === 'ArrowLeft') {
            navigateImage(-1);
        } else if (event.key === 'ArrowRight') {
            navigateImage(1);
        }
    }

    // Handle touch start
    function handleTouchStart(event) {
        startX = event.touches[0].clientX;
    }

    // Handle touch end
    function handleTouchEnd(event) {
        if (!startX) return;

        const endX = event.changedTouches[0].clientX;
        const diffX = startX - endX;

        if (Math.abs(diffX) > 50) { // Minimum swipe distance to be detected
            if (diffX > 0) {
                // Swipe left
                navigateImage(1);
            } else {
                // Swipe right
                navigateImage(-1);
            }
        }
        startX = null;
    }

    // Navigate to the previous or next image in an infinite loop
    function navigateImage(direction) {
        const group = document.querySelector('.fatbox img').getAttribute('src');
        const currentGroup = Object.keys(fatboxGroups).find(key => fatboxGroups[key].includes(group));
        const groupLength = fatboxGroups[currentGroup].length;

        imagePos = (imagePos + direction + groupLength) % groupLength; // Infinite loop logic
        document.querySelector('.fatbox img').src = fatboxGroups[currentGroup][imagePos];
    }

    // Close the fatbox
    function closeFatbox() {
        const fatboxElement = document.querySelector('.fatbox');
        if (fatboxElement) {
            fatboxElement.remove();
            document.removeEventListener('keydown', handleKeydown);
            imagePos = null;
        }
    }

});
