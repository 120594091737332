/*--------------------------------------------------------
Höhe der Boxen anpassen
--------------------------------------------------------*/
$(document).ready(function(){
	$('body').addClass('ready');
	//if($( window ).width() > 768) {
		setTimeout(function(){/*wegen bildern korrekte hoehe*/
			$('.gleichehoehe').each(function(){
				var highestBox = 0;
				$(this).find('.box-item').each(function(){
					if($(this).outerHeight() > highestBox){
						highestBox = $(this).outerHeight();
					}
				})
				$(this).find('.box-item').css('height',highestBox+'px');
			});
		 },1000);
 	//}
});

window.onresize = function(event) {
	$('.gleichehoehe .box-item').css('height','auto');
   // if($( window ).width() > 768) {
		$('.gleichehoehe').each(function(){
			var highestBox = 0;
			$(this).find('.box-item').each(function(){
				if($(this).outerHeight() > highestBox){
					highestBox = $(this).outerHeight();
				}
			})
			$(this).find('.box-item').outerHeight(highestBox);
	   });
	// }
};