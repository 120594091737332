/*--------------------------------------------------------
	Fixed Buttons
--------------------------------------------------------*/
$(document).ready(function() {
	if ($(".footer-fixed-button").length > 0) {
		$("#phone").on("click", function() {
			$("#help-phone").slideToggle();
			return false;
		});
		$("#help-phone .close").on("click", function() {
			$("#help-phone").slideUp();
		});
		$(".mod_article").on("click", function() {
			$("#help-phone").slideUp();
		});
	}
});

/*--------------------------------------------------------
	Socialmedia Icons
--------------------------------------------------------*/
$(document).ready(function() {
	$(function() {
		$(window).scroll(function() {
			if ($(this).scrollTop() > 200) {
				if ($(window).width() >= 768) {
					$(".ausblenden").slideUp();
				}
			} else {
				if ($(window).width() >= 768) {
					$(".ausblenden").slideDown();
				}
			}
		});
	});
});
