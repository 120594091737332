
/*--------------------------------------------------------
 Show Header at mousemove and scroll
--------------------------------------------------------*/
(function(h, f, n) {
    var a = h.querySelector("#header-container");
    if (!a) return !0;
    var k = 0,
        l = 0,
        b = 0,
        m = 0,
        g = 0,
        d = function(a, c) {
            return a.classList ? a.classList.contains(c) : (new RegExp("(^| )" + c + "( |$)", "gi")).test(a.className)
        },
        e = function(a, c) {
            a.classList ? a.classList.remove(c) : a.className = a.className.replace(new RegExp("(^|\\b)" + c.split(" ").join("|") + "(\\b|$)", "gi"), " ")
        };
    f.addEventListener("scroll", function(a, c) {
        var b, e;
        return function() {
            var f = this,
                g = arguments,
                d = +new Date;
            b && d < b + a ? (clearTimeout(e), e = setTimeout(function() {
                b =
                    d;
                c.apply(f, g)
            }, a)) : (b = d, c.apply(f, g))
        }
    }(400, function() {
        k = h.body.offsetHeight;
        l = f.innerHeight;
        b = f.pageYOffset;
        g = m - b;
        400 < b ? d(a, "header--narrow") || (a.classList ? a.classList.add("header--narrow") : a.className += " header--narrow") : e(a, "header--narrow");
        300 >= b ? e(a, "header--hidden") : 0 < g && d(a, "header--hidden") ? e(a, "header--hidden") : 0 > g && (b + l >= k && d(a, "header--hidden") ? e(a, "header--hidden") : a.classList ? a.classList.add("header--hidden") : a.className += " header--hidden");
        m = b
    }))
})(document, window, 0);