
$(document).ready(function() {
    merklisteInit();
    initSaveButton();
});

function initSaveButton(){
    $('.save').on('click',function(){
        var merkliste = Cookies.get('merkliste');
        var merklistejson = jQuery.parseJSON(merkliste);
        var anzahlObjects = merklistejson.merkliste.length;
        //alert(anzahlObjects);
        if(anzahlObjects == 9){
            alert("Maximal können 9 Fahrzeug gespeichert werden");
        } else {
            $(this).parent().parent().parent().toggleClass('active');
            $(this).toggleClass('active');        
        }
        if(istFahrzeugVorhanden($(this).attr('fahrzeugid'))){
            deleteFahrzeug($(this).attr('fahrzeugid'));
        } else {
            insertFahrzeug($(this).attr('fahrzeugid'),$(this).attr('fahrzeugname'),$(this).attr('subtitel'),$(this).attr('link'),$(this).attr('bild'),$(this).attr('ez'),$(this).attr('km'),$(this).attr('kwps'),$(this).attr('ps'),$(this).attr('kraftsoff'),$(this).attr('preis'));
        }
    });
}

 
function istFahrzeugVorhanden(id){
    var produkt = false;
	if(Cookies.get('merkliste')) {	
		var merklistejson = Cookies.get('merkliste');		
		var obj = jQuery.parseJSON(merklistejson); 
		return_value = false; 
        
        for (var i = obj['merkliste'].length - 1; i >= 0; --i) {
            if (parseInt(obj['merkliste'][i].id) == parseInt(id)) {
               return_value = true;
            }
        }
        
        /* 
        $.each( obj['merkliste'], function( key, value ) {
			if(value != null) {
                if(parseInt(id) == parseInt(value.id)) {
                   return_value = true;
			  }
			}
        });
        */
    }
    return return_value;
} 

function insertFahrzeug(id,titel,subtitel,link,bild,ez,km,kw,ps,kraftsoff,preis){
	var neueFahrzeug = '{"id":'+id+',"titel":"'+titel+'","link":"'+link+'","bild":"'+bild+'","subtitel":"'+subtitel+'","ez":"'+ez+'","km":"'+km+'","kw":"'+kw+'","ps":"'+ps+'","kraftsoff":"'+kraftsoff+'","preis":"'+preis+'"}';
	var merkliste = Cookies.get('merkliste');
	var merklistejson = jQuery.parseJSON(merkliste);
    var anzahlObjects = merklistejson.merkliste.length; 
    
    if(anzahlObjects < 9){
        merklistejson.merkliste[anzahlObjects] = jQuery.parseJSON(neueFahrzeug);
        Cookies.set('merkliste', merklistejson, { expires: 14 });
        var anzahlObjects = merklistejson.merkliste.length; 

        if($('.ce_rsce_fahrzeugsuche_liste').length && anzahlObjects > 0 && $('.favoriten-row').length <= 0){
            $('.ce_rsce_fahrzeugsuche_liste #wrapper_fzg_liste').prepend('<div class="favoriten-row"><a class="button" href="/favoriten.html"><i class="icons-heart"></i>Favoriten anzeigen</a></div>');
        }
    }

    /*
    if(anzahlObjects > 0) {
        $('.gemerkte-anzeigen').addClass('active');
        $('#header .mod_navigation ul li.favoriten').addClass('active');
        if($('#header .mod_navigation ul li.favoriten a .badge').length > 0){
            $('#header .mod_navigation ul li.favoriten a .badge').html(anzahlObjects);
        } else {
            $('#header .mod_navigation ul li.favoriten a').append('<span class="badge">'+anzahlObjects+'</span>');
        }
    } else {
        $('.gemerkte-anzeigen').removeClass('active'); 
        $('#header .mod_navigation ul li.favoriten').removeClass('active');
        $('#header .mod_navigation ul li.favoriten a .badge').remove();
        
    }  
    $('.gemerkte-anzeigen span').html(anzahlObjects);
    */
} 

function deleteFahrzeug(id){
	var merklistejson = Cookies.get('merkliste');
	var obj = jQuery.parseJSON(merklistejson);

   for (var i = obj['merkliste'].length - 1; i >= 0; --i) {
        if (parseInt(obj['merkliste'][i].id) == parseInt(id)) {
            obj['merkliste'].splice(i,1);
        }
    }

    Cookies.set('merkliste', obj, { expires: 14 });

    var anzahlObjects = obj.merkliste.length;

    //console.log(anzahlObjects);
    
    if(anzahlObjects > 0) {
        
    } else {
        $('.favoriten-row').remove();
    }
}

function merklisteInit(){
    var merklistejson = Cookies.get('merkliste');
	if(merklistejson) {	
		//console.log("Merkliste vorhanden");
	} else {
		Cookies.set('merkliste', jQuery.parseJSON('{"merkliste":[]}'), { expires: 14 });
    }

    var merklistejson = Cookies.get('merkliste'); 
    var obj = jQuery.parseJSON(merklistejson);
    var anzahl = 0;
	$.each( obj['merkliste'], function( key, value ) {
		if(value != null) {
          $('#fahrzeuganzeige-'+value.id).addClass('active');
          $('#fahrzeuganzeige-'+value.id+ ' .save').addClass('active');
          anzahl++;
		}
    });

    if($('.ce_rsce_fahrzeugsuche_liste:not(.favoriten)').length > 0 && anzahl > 0 && $('.favoriten-row').length <= 0){
        $('.ce_rsce_fahrzeugsuche_liste #wrapper_fzg_liste').prepend('<div class="favoriten-row"><a class="button" href="/favoriten.html"><i class="icons-heart"></i>Favoriten anzeigen</a></div>');
    }


    if($('#favoriten-liste').length > 0){
        $('#favoriten-liste .tabellen').append('<table><tr><td>Fahrzeuge</td><td></td></tr></table>');

        $.each( obj['merkliste'], function( key, value ) {
            if(value != null) { 
                $('#favoriten-liste table').append("<tr><td><a href='"+value.link+"' title='"+value.titel+"'>"+value.titel+"</a></td><td><a href='"+value.link+"' title='"+value.titel+"'><i class='icons-right-open-big'></i></a></td>");
            }
        });
    } 

    if($('.ce_rsce_fahrzeugsuche_liste.favoriten').length > 0){
        
        $.each( obj['merkliste'], function( key, value ) {
            if(value != null) { 
                $('.ce_rsce_fahrzeugsuche_liste.favoriten #wrapper_fzg_liste .its_row.all').append(
                    '<div class="its_col-md-4 its_col-sm-6">'+
                        '<div class="list_fzg_box">' +
                            '<div class="list_img_box">' +
                                '<a class="img_link" href="'+value.link+'">' +
                                    '<img class="list_img img-responsive" src="'+value.bild+'">' +
                                '</a>' +
                            '</div>'+
                            '<div class="its_row info_wrapper">' +
                                '<div class="its_col-sm-12 its_col-xs-12 ">' +
                                    '<a class="fzg_title" href="'+value.link+'">'+value.titel+'<br><span class="sub_title"> ' + value.subtitel + '&nbsp;</span></a>' +
                                '</div>' +
                                '<div class="its_col-sm-6 its_col-xs-6 info1">' +
                                    '<span class="erstzulassung">EZ '+value.ez+'</span><br>' +
                                    '<span class="kilometer">'+value.km+' km</span><br>' +
                                '</div>' +
                                '<div class="its_col-sm-6 its_col-xs-6 info2">' +
                                    '<span class="ps">'+value.kw+'kW ('+value.ps+'PS)</span><br/>' +
                                    '<span class="kraftstoff">'+value.kraftsoff+'</span><br/>' +
                                '</div>' +
                            '</div>' +
                            '<div class="its_row">' +
                                '<div class="its_col-sm-12">' +
                                    '<a class="list_preis its_btn its_btn-primary its_btn-block" href="'+value.link+'">' +
                                        '<span class="preis_brutto">' +
                                         value.preis + ' <small>inkl. 19% MwSt.</small>' +
                                        '</span>' +
                                    '</a>' +
                                '</div>' +
                            '</div>' +
                        '</div>'+
                    '</div>'
                );
            }
        });
    }


    /*
    if(anzahl > 0) {
        $('.gemerkte-anzeigen').addClass('active');
        $('#header .mod_navigation ul li.favoriten').addClass('active');
        $('#header .mod_navigation ul li.favoriten a').append('<span class="badge">'+anzahl+'</span>');
    } else {
        $('.gemerkte-anzeigen').removeClass('active');
        $('#header .mod_navigation ul li.favoriten').removeClass('active');
    }

    $('.gemerkte-anzeigen span').html(anzahl);
    */
    
}