// $.fn.extend({
// 	drawMap: function(lat, lon, zoom, style, pin) {
// 		var el = this[0];
// 		if (style === null || style === "standard") {
// 			style = "voyager";
// 		} else if (style === "light") {
// 			style = "light_all";
// 		} else {
// 			console.error(style + " is not a valid style.");
// 		}
// 		var mymap = L.map(el, { scrollWheelZoom: false }).setView([lat, lon], zoom);
// 		var CartoDB_Voyager = L.tileLayer(
// 			"https://{s}.basemaps.cartocdn.com/rastertiles/" +
// 				style +
// 				"/{z}/{x}/{y}{r}.png",
// 			{
// 				attribution:
// 					'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
// 				subdomains: "abcd",
// 				maxZoom: 19
// 			}
// 		).addTo(mymap);
// 		var opt = {};
// 		if (pin !== undefined && pin !== null) {
// 			opt.icon = pin;
// 		}
// 		var marker = L.marker([lat, lon], opt).addTo(mymap);
// 		mymap.on("focus", function() {
// 			mymap.scrollWheelZoom.enable();
// 		});
// 		mymap.on("blur", function() {
// 			mymap.scrollWheelZoom.disable();
// 		});
// 	}
// });
