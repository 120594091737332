/*--------------------------------------------------------
	Guided Tour PopUp
--------------------------------------------------------*/
$( document ).ready(function() {
    $('.ce_rsce_emobility_guidedtour .button').on('click',function(){
        $('#emobility-popup-guidedtour').addClass('active');
        if(!$('#emobility-popup-guidedtour').find('iframe').attr('src')){
            $('#emobility-popup-guidedtour').find('iframe').attr('src',$('#emobility-popup-guidedtour').find('iframe').attr('iframe-src'));
        }
    });
    $('#emobility-popup-guidedtour .close-area,#emobility-popup-guidedtour .close').on('click',function(){
        $('#emobility-popup-guidedtour').removeClass('active');
    });
});  