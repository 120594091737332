$(document).ready(function() {
    if($("#job-results").length > 0){
        $.get( "https://www.auto-roehr.de/jobboerse.php", function( data ) {
            //$( "#job-results" ).append( data );
            data = JSON.parse(data);
            //data = data.reverse();
            $.each(data, function(idx, angebot){
                //$("#job-results table").append('<tr><td>'+angebot.PublishingDate + '</td><td>' + angebot.VacancyName + "</td><td>"+angebot.LocationList+"</td><td><a href='"+angebot.ApplyUrl+"'><i class='icons-mail'></i></td></tr>");

                var Orte = "<ul>";
                $.each(angebot.LocationList, function(idx, ort){
                    Orte += "<li><a href='/standorte.html' target='_blank'>"+ort.LocationName + "</a></li>";
                });
                Orte += "</ul>";

                var nowDate = new Date(angebot.PublishingDate);
                var curr_date = nowDate.getDate();
                if(curr_date < 10){
                    curr_date = "0"+curr_date;
                }
                var curr_month = nowDate.getMonth()+1;
                if(curr_month < 10){
                    curr_month = "0"+curr_month; 
                }
                var curr_year = nowDate.getFullYear();
                //<td><a href='mailto:"+angebot.VacancyUrl+"' target='_blank' title='"+angebot.VacancyName+"'><i class='icons-share'></i></a></td>
                $("#job-results table tbody").append('<tr><td>'+curr_date+"."+curr_month+"."+curr_year+'</td><td><a href="'+angebot.VacancyUrl+'" target="_blank" title="'+angebot.VacancyName+'">' + angebot.VacancyName + "</a></td><td>"+Orte+"</td><td><a href='"+angebot.ApplyUrl+"'><i class='icons-mail'></i></a></td></tr>");

            });
        });
    }
}); 