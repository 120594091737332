
require("./fahrzeugAPIFilter.js");
require("./fahrzeugAPI.js");
require("./fahrzeugAPIMerkliste.js");
require("./fahrzeugAPI-Schnellsuche.js");

require("./rsce_ansprechpartner.js");



require("./coockie.js");
require("./cookiebar.js");
require("./countAnimation.js");

require("./finanzierungsrechner.js");
require("./fixedHeader.js");



require("./instagram.js");
require("./iphonex.js");

require("./lightbox-fatbox.js");


require("./kein-rechtsklick.js");
require("./kontaktformular_strecke.js");
require("./leaflet.min.js");

require("./map.js");
require("./map.min.js");
require("./mobileNavigation.js");
require("./print.js");
require("./respimage.min.js");
require("./rsce_emobility_guidedtour.js");
require("./rsce_infobox_popup.js");
require("./rsce_youtube.js");
require("./smoothscrolling.js");
require("./standorte.js");
require("./stellenangebote.js");
require("./stretchy.js");
require("./svgFallback.js");
require("./swipebox.min.js");
require("./tabellen.js");
require("./tablesort.js");
require("./blockMapsFonts.js");
require("./corona.js");
require("./datePicker.min.js");
//require("./fahrzeugsuche-async.js");
require("./favoriten.js");
require("./fixierteButtons.js");
require("./gleichehoehe.js");
require("./hersteller-connect-popup.js");
require("./isOnScreen.js");
//require("./itsmobile-fahrzeugschnellsuche.js");
//require("./angebote-in-fahrzeugsuche.js");

require("./usercentrics.js");



const AOS = require("aos");

AOS.init({
	duration: 2000,
	easing: 'ease-out-sine',
	startEvent: 'load'
});

document.querySelectorAll("img").forEach(img => {
	img.addEventListener("load", () => {
		AOS.refresh();
	})
})






$(document).ready(function() {
	$("html").addClass("ready");
});




/*--------------------------------------------------------
	iframe
--------------------------------------------------------*/


$(document).ready(function() {
	$("iframe").each(function(index) {
		if ($(this).attr("data-src")) {
			$(this).attr("src", $(this).attr("data-src"));
		}
	});
	$("img").each(function(index) {
		if ($(this).attr("data-src")) {
			$(this).attr("src", $(this).attr("data-src"));
		}
	});

	$(".data-src-background").each(function(index) {
		if ($(this).attr("data-src")) {
			$(this).css("background-image", "url(" + $(this).attr("data-src") + ")");
		}
	});
});

//Leaflet
// window.onload = function() {
// 	$(".map").each(function() {
// 		var lat = $(this).attr("lat");
// 		var lng = $(this).attr("lng");
// 		var pin = L.icon({
// 			iconUrl: "/files/themes/auto-roehr/images/pin.png",
// 			iconSize: [26, 40],
// 			iconAnchor: [23, 40]
// 		});
// 		$(this).drawMap(lat, lng, 14, "light", pin);
// 	});
// };




$(".ce_rsce_teaser:not(.no-slider) .slider").slick({
	dots:true,
	infinite: true,
	speed: 1000,
	slidesToShow: 1,
	arrows: true,
	autoplay: true,
	adaptiveHeight: true,
	//autoplaySpeed: 5000,
	autoplaySpeed: 9000,
	prevArrow: '<span class="prev"><i class="icons-pfeil-links"></i></span>',
	nextArrow: '<span class="next"><i class="icons-pfeil-rechts"></i></span>',
});

$(document).ready(function() {
	if ($(".ce_rsce_teaser").length > 0) {
		$(".touch .hinweis-icon").on("click", function() {
			$(this).addClass("active");
		});
	}
	window.onscroll = function() {
		$(".touch .hinweis-icon").removeClass("active");
	};
});

$(".slider-wrapper").slick({
	dots: true,
	infinite: true,
	speed: 1000,
	slidesToShow: 1,
	arrows: true,
	autoplay: false,
	autoplaySpeed: 3000,
	prevArrow: '<span class="prev"><i class="icons-pfeil-links"></i></span>',
	nextArrow: '<span class="next"><i class="icons-pfeil-rechts"></i></span>'
});

$(".ce_rsce_kundenstimmen ul.slider").slick({
	arrows: true,
	prevArrow: '<span class="prev"><i class="icons-pfeil-links"></i></span>',
	nextArrow: '<span class="next"><i class="icons-pfeil-rechts"></i></span>',
	slidesToShow: 1,
	variableWidth: false,
	autoplay: true,
	autoplaySpeed: 5000,
	dots: true,
	infinite: true,
	speed: 500,
	fade: false,
	cssEase: "linear"
});

$(".news-slider").slick({
	arrows: true,
	prevArrow: '<span class="prev"><i class="icons-pfeil-links"></i></span>',
	nextArrow: '<span class="next"><i class="icons-pfeil-rechts"></i></span>',
	slidesToShow: 3,
	variableWidth: false,
	autoplay: false,
	autoplaySpeed: 3000,
	dots: false,
	infinite: true,
	speed: 500,
	fade: false,
	cssEase: "linear",
	responsive: [
		{
			breakpoint: 1200,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2
			}
		},
		{
			breakpoint: 680,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			}
		}
	]
});

$(".ce_rsce_angebotsslider .mod_eventlist .event-slider").slick({
	arrows: true,
	prevArrow: '<span class="prev"><i class="icons-pfeil-links"></i></span>',
	nextArrow: '<span class="next"><i class="icons-pfeil-rechts"></i></span>',
	slidesToShow: 3,
	variableWidth: false,
	autoplay: false,
	autoplaySpeed: 3000,
	dots: false,
	infinite: false,
	speed: 500,
	fade: false,
	cssEase: "linear",
	responsive: [
		{
			breakpoint: 1200,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2
			}
		},
		{
			breakpoint: 680,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			}
		}
	]
});






