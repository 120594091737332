$(document).ready(function() {
    if($('.corona-data').length > 0){
        $.getJSON( "https://www.auto-roehr.de/corona/data.json", function( data ) {
            var items = [];
            $.each( data, function( key, val ) {
                if(parseInt(val) <= 50){
                    items.push( "<li>" + key + ": <strong style='color:#00943C'>" + val + "</strong></li>" );
                }
                if(parseInt(val) > 50 && parseInt(val) <= 100){
                    items.push( "<li>" + key + ": <strong style='color:#F7A600'>" + val + "</strong></li>" );
                }
                if(parseInt(val) > 100 && parseInt(val) <= 200){
                    items.push( "<li>" + key + ": <strong style='color:#EC6607'>" + val + "</strong></li>" );
                }
                if(parseInt(val) > 200){
                    items.push( "<li>" + key + ": <strong style='color:#BE1623'>" + val + "</strong></li>" );
                }
            });
            $( "<ul/>", {
                html: items.join( "" )
            }).appendTo( ".corona-data" );
        });
    }
});