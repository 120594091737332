/*--------------------------------------------------------
isOnScreen
--------------------------------------------------------*/
! function(t) {
    t.fn.isOnScreen = function(o, e) {
        (null == o || "undefined" == typeof o) && (o = 1), (null == e || "undefined" == typeof e) && (e = 1);
        var i = t(window),
            r = {
                top: i.scrollTop(),
                left: i.scrollLeft()
            };
        r.right = r.left + i.width(), r.bottom = r.top + i.height();
        var f = this.outerHeight(),
            n = this.outerWidth();
        if (!n || !f) return !1;
        var h = this.offset();
        h.right = h.left + n, h.bottom = h.top + f;
        var l = !(r.right < h.left || r.left > h.right || r.bottom < h.top || r.top > h.bottom);
        if (!l) return !1;
        var m = {
            top: Math.min(1, (h.bottom - r.top) / f),
            bottom: Math.min(1, (r.bottom - h.top) / f),
            left: Math.min(1, (h.right - r.left) / n),
            right: Math.min(1, (r.right - h.left) / n)
        };
        return m.left * m.right >= o && m.top * m.bottom >= e
    }
}(jQuery);
$(document).ready(function() {
    setTimeout(function() {
        $(".fade").each(function(index) {
            if ($(this).isOnScreen(.2, .2)) $(this).addClass("is-visible")
        })
    }, 1E3);
    window.addEventListener("scroll", function(e) {
        $(".fade").each(function(index) {
            if ($(this).isOnScreen(.5, .5)) $(this).addClass("is-visible")
        })
    })
});