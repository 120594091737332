(function(){
    // Really basic check for the ios platform
    // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  
    // Get the device pixel ratio
    var ratio = window.devicePixelRatio || 1;
  
    // Define the users device screen dimensions
    var screen = {
      width : window.screen.width * ratio,
      height : window.screen.height * ratio
    };
  
    // iPhone X Detection
    if (iOS && screen.width == 1125 && screen.height === 2436) {
      $('body').addClass('iphonex');
    } 
  })(); 