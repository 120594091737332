/*Stretchy: Form element autosizing, the way it should be*/
/*
(function() {
    if (!self.Element) return;
    if (!Element.prototype.matches) Element.prototype.matches = Element.prototype.webkitMatchesSelector || Element.prototype.mozMatchesSelector || Element.prototype.msMatchesSelector || Element.prototype.oMatchesSelector || null;
    if (!Element.prototype.matches) return;

    function $$(expr, con) {
        return expr instanceof Node || expr instanceof Window ? [expr] : [].slice.call(typeof expr == "string" ? (con || document).querySelectorAll(expr) : expr || [])
    }
    var _ = self.Stretchy = {
        selectors: {
            base: 'input[type="search"]',
            filter: "*"
        },
        script: document.currentScript || $$("script").pop(),
        resize: function(element) {
            if (!_.resizes(element)) return;
            var cs = getComputedStyle(element);
            var offset = 0;
            if (!element.value && element.placeholder) {
                var empty = true;
                element.value = element.placeholder
            }
            var type = element.nodeName.toLowerCase();
            if (type == "textarea") {
                element.style.height = "0";
                if (cs.boxSizing == "border-box") offset = element.offsetHeight;
                else if (cs.boxSizing == "content-box") offset = -element.clientHeight;
                element.style.height = element.scrollHeight + offset + "px"
            } else if (type == "input") {
                element.style.width = "0";
                if (cs.boxSizing == "border-box") offset = element.offsetWidth;
                else if (cs.boxSizing == "padding-box") offset = element.clientWidth;
                element.scrollLeft = 1E10;
                var width = Math.max(element.scrollLeft + offset, element.scrollWidth - element.clientWidth);
                element.style.width = width + "px"
            } else if (type == "select") {
                var selectedIndex = element.selectedIndex > 0 ? element.selectedIndex : 0;
                var option = document.createElement("_");
                option.textContent = element.options[selectedIndex].textContent;
                element.parentNode.insertBefore(option, element.nextSibling);
                var appearance;
                for (var property in cs)
                    if (!/^(width|webkitLogicalWidth|length)$/.test(property)) {
                        option.style[property] = cs[property];
                        if (/appearance$/i.test(property)) appearance = property
                    } option.style.width = "";
                if (option.offsetWidth > 0) {
                    element.style.width = option.offsetWidth + "px";
                    if (!cs[appearance] || cs[appearance] !== "none") element.style.width = "calc(" + element.style.width + " + 2em)"
                }
                option.parentNode.removeChild(option);
                option = null
            }
            if (empty) element.value = ""
        },
        resizeAll: function(elements) {
            $$(elements || _.selectors.base).forEach(function(element) {
                _.resize(element)
            })
        },
        active: true,
        resizes: function(element) {
            return element && element.parentNode && element.matches && element.matches(_.selectors.base) && element.matches(_.selectors.filter)
        },
        init: function() {
            _.selectors.filter = _.script.getAttribute("data-filter") || ($$("[data-stretchy-filter]").pop() || document.body).getAttribute("data-stretchy-filter") || Stretchy.selectors.filter || "*";
            _.resizeAll()
        },
        $$: $$
    };
    if (document.readyState !== "loading") _.init();
    else document.addEventListener("DOMContentLoaded", _.init);
    var listener = function(evt) {
        if (_.active) _.resize(evt.target)
    };
    document.documentElement.addEventListener("input", listener);
    document.documentElement.addEventListener("change", listener);
    if (self.MutationObserver)(new MutationObserver(function(mutations) {
        if (_.active) mutations.forEach(function(mutation) {
            if (mutation.type == "childList") Stretchy.resizeAll(mutation.addedNodes)
        })
    })).observe(document.documentElement, {
        childList: true,
        subtree: true
    })
})();
*/
