
(function(e) {
	e(function() {
		e("#header .inside").append(e('<div class="nav-mobile-hamburger"><div class="schrift">Menü</div><span id="hamburger"></span></div>'));
		e("#header .nav-item.submenu").prepend('<span class="nav-click"><i class="icons-right-open-big"></i></span>');
		e("#header .nav-mobile-hamburger").click(function() {
			e("#header .nav-item.sibling.last").removeClass("sibling");
			e("#header-container").toggleClass("offen");
			e("#header .mod_navigation").toggleClass("offen");
			e("#header .mod_navigation>ul").slideToggle("offen");
			e("#header .nav-mobile-hamburger").toggleClass("offen");
		});
		e(".nav-click").on("click", function() {
			e(this)
				.siblings(".nav-submenu")
				.slideToggle();
			e(this).toggleClass("nav-rotate");
		});
	});
})(jQuery);

/*--------------------------------------------------------
	Suchefeld
--------------------------------------------------------*/
function buttonUp(){var inputVal=$('.searchbox-input').val();inputVal=$.trim(inputVal).length;if(inputVal!==0){$('.searchbox-icon').css('display','none')}else{$('.searchbox-input').val('');$('.searchbox-icon').css('display','block')}};

$(document).ready(function(){
	var submitIcon=$('.searchbox-icon');
	var inputBox=$('.searchbox-input');
	var searchBox=$('.searchbox');
	var isOpen=false;
	submitIcon.click(function(){
		if(isOpen==false){
			searchBox.addClass('searchbox-open');
			inputBox.focus();
			isOpen=true;
		}else{
			searchBox.removeClass('searchbox-open');
			inputBox.focusout();
			isOpen=false;
		}
	});
	submitIcon.mouseup(function(){return false});
	searchBox.mouseup(function(){return false});
	$(document).mouseup(function(){
		if(isOpen==true){
			$('.searchbox-icon').css('display','block');submitIcon.click()
		}
	});
});