/*--------------------------------------------------------
    Youtube Opt-In
--------------------------------------------------------*/
$('.videoWrapper .overlay .play').on("click",function(){
    $('.videoWrapper .overlay').fadeOut();
    $( ".videoWrapper" ).each(function( index ) {
        $(this).addClass('loading');
        $(this).find('iframe').attr('src',$(this).find('iframe').attr('video-url'));
    });
    // $(this).parent().parent().fadeOut();
    // $(this).parent().parent().prev().attr('src',$(this).parent().parent().prev().attr('video-url'));
    localStorage.setItem('YouTube','show');
});

$( document ).ready(function() {
	if(localStorage.getItem('YouTube') == "show") {
        $('.videoWrapper .overlay').fadeOut();
        $( ".videoWrapper" ).each(function( index ) {
            $(this).addClass('loading');
            $(this).find('iframe').attr('src',$(this).find('iframe').attr('video-url'));
        });
    } 
});
