/*--------------------------------------------------------
	PopUp
--------------------------------------------------------*/
$( document ).ready(function() {
    $('.ce_rsce_infoboxen_popup .bilder .bild').on('click',function(){
        $('#infoboxen-popup .inside .content').html($(this).find('.popup-content').html());
        $('#infoboxen-popup').addClass('active');
    });
    $('#infoboxen-popup .close-area,#infoboxen-popup .close').on('click',function(){
        $('#infoboxen-popup').removeClass('active');
    });
});  