  
/*
function isNumber(str) {
    if (typeof str != "string") return false // we only process strings!
    // could also coerce to string: str = ""+str
    return !isNaN(str) && !isNaN(parseFloat(str))
}
var is_active = false;
setInterval(function(){
    if($('.ce_rsce_fakten .area .headline').length > 0){
            $('.ce_rsce_fakten .area .headline').each(function () {
            var $this = $(this);
            var num = $this.text();
            if(isNumber(num)){
                jQuery({ Counter: 0 }).animate({ Counter: $this.text() }, {
                    duration: 2000,
                    easing: 'swing',
                    step: function () {
                        $this.text(Math.ceil(this.Counter));
                    }
                });
            }
        });
    }
},2000);
*/