
$( document ).ready(function() {

   var standorte = ["passau", "vilshofen", "tittling","grafenau"];
   var i = 0;
   var stopInterval = false;
    setInterval(function() {
        if($(window).width() > 768){
            if(stopInterval == false){
                $('.ce_rsce_standorte').attr('class','ce_rsce_standorte');
                var standort = standorte[i++];
                $('.ce_rsce_standorte').addClass(standort);
                if(i >= standorte.length) i = 0;
            }
        }
    }, 4000);

    $('.ce_rsce_standorte .standort').on('click',function(){
        if($(window).width() <= 768){
            $('.ce_rsce_standorte').attr('class','ce_rsce_standorte');
            $('.ce_rsce_standorte').addClass($(this).attr('data-standort'));
            stopInterval = true;
            $([document.documentElement, document.body]).animate({
                scrollTop: $("#standort-liste").offset().top
            }, 2000);
        } else {
            $('.ce_rsce_standorte').attr('class','ce_rsce_standorte');
            $('.ce_rsce_standorte').addClass($(this).attr('data-standort'));
            stopInterval = true;
        }
    });

});