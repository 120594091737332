

const fahrzeugsuche = document.querySelector('.ce_rsce_fahrzeugSucheAPI');

const merklisteFixed = document.querySelector('.ce_rsce_fahrzeugSucheAPI .merklistefixed');

let merklisteOpen = false;

if (fahrzeugsuche){
    setTimeout(()=>{
        let merkElement = fahrzeugsuche.querySelectorAll('.merkliste');
        if (merkElement.length > 0){
            merkElement.forEach(merk => {
                merk.addEventListener('click', (event)=> {
                    event.preventDefault();
                    merkStorage(merk.getAttribute('data-carId'), merk);
                    detectMerkCars();
                })
            })
            detectMerkCars();
            
        }

        let detailMerkElement = fahrzeugsuche.querySelector('.merklistefixed.detail')
        if (detailMerkElement){
            detailMerkElement.addEventListener('click', ()=> {
                let queryString = window.location.href.split('?')[1];
                let params = new URLSearchParams(queryString);
                let id = params.get('id');
                merkStorage(id);
            })
        }

        let merklisteCheck = fahrzeugsuche.getAttribute('data-merkliste');
        if (merklisteCheck === 'Ja'){
            showMerklist();
        }

        if (merklisteFixed){
            merklisteFixed.addEventListener('click', ()=> {
                if (localStorage.getItem('fahrzeugMerkliste')){
                    let merklist = localStorage.getItem('fahrzeugMerkliste');
                    if (merklist.length > 0){
                        showMerklist();
                        merklisteOpen = true;
                        let fahrzeuge = document.querySelector('.ce_rsce_fahrzeugSucheAPI .filter-container.buttons');
                        fahrzeuge.scrollIntoView({behavoir: 'smooth'});
                    } else {
                        // Redirect zur Merklisten erklärung
                    }
                }
                
            })
        }
    }, 500)

}





function merkStorage(id , car){
    console.log(id)
    if(id){
        if (localStorage.getItem('fahrzeugMerkliste')){
            let merkliste = localStorage.getItem('fahrzeugMerkliste');
            if (merkliste.includes(id) === false){
                merkliste = merkliste + id + ';';
                localStorage.setItem('fahrzeugMerkliste', merkliste);
                detectMerkCars();
            } else {
                merkliste = merkliste.replace(new RegExp(`${id};?`), '');
                localStorage.setItem('fahrzeugMerkliste', merkliste);
            
                localStorage.getItem('fahrzeugMerkliste').length === 0 ? localStorage.removeItem('fahrzeugMerkliste') : '';

                if (merklisteOpen){
                    car.parentElement.style.display = 'none';
                }
                detectMerkCars();
            }
        } else {
            let merkliste = id + ';';
            localStorage.setItem('fahrzeugMerkliste', merkliste);
            detectMerkCars();
        }
    }
}

function detectMerkCars(){
    if (localStorage.getItem('fahrzeugMerkliste')){
        let counter = 0;
        let merkliste = localStorage.getItem('fahrzeugMerkliste');
        let merkElement = fahrzeugsuche.querySelectorAll('.merkliste');
        merkElement.forEach(element => {
            if (merkliste.includes(element.getAttribute('data-carId'))){
                element.classList.add('active');
                counter++;
            } else {
                element.classList.remove('active');
    
            }
        })
        if (counter >= 0){
            fahrzeugsuche.querySelector('.merklistefixed .anzahl').innerText = counter;
        }
    } else {
        let merkElement = fahrzeugsuche.querySelectorAll('.merkliste');
        merkElement.forEach(element => {
            element.classList.remove('active');
            fahrzeugsuche.querySelector('.merklistefixed .anzahl').innerText = "0";
        })
        let fahrzeuge = fahrzeugsuche.querySelectorAll('.fahrzeug');
        fahrzeuge.forEach(fahrzeug => {
            fahrzeug.style.display = 'block';
        })
    }
   
}

function showMerklist(){
    if (localStorage.getItem('fahrzeugMerkliste')){
        let counter = 0;
        let merkliste = localStorage.getItem('fahrzeugMerkliste');
        let fahrzeuge = fahrzeugsuche.querySelectorAll('.fahrzeug');

        fahrzeuge.forEach(fahrzeug => {
            let id = fahrzeug.getAttribute('data-carId');
            if (merkliste.includes(id)){
                fahrzeug.style.display = 'block';
                counter++;
            } else {
                fahrzeug.style.display = 'none';
            }
        })

        if (counter === 0){
            fahrzeuge.forEach(fahrzeug => {
                fahrzeug.style.display = 'block';
            })
            fahrzeugsuche.querySelector('.infoBox .infoText').innerText = 'Kein Fahrzeug auf der Merkliste';
        } else {
            fahrzeugsuche.querySelector('.infoBox .infoText').innerText = '';
        }
    } else {
        let fahrzeuge = fahrzeugsuche.querySelectorAll('.fahrzeug');
        fahrzeuge.forEach(fahrzeug => {
            fahrzeug.style.display = 'block';
            fahrzeugsuche.querySelector('.infoBox .infoText').innerText = 'Kein Fahrzeug auf der Merkliste';
        })
        merklisteOpen = false;
    }
}






