/*--------------------------------------------------------
	JavaScript Cookie v2.1.3
--------------------------------------------------------*/	
(function(factory){var registeredInModuleLoader=false;if(typeof define==="function"&&define.amd){define(factory);registeredInModuleLoader=true}if(typeof exports==="object"){module.exports=factory();registeredInModuleLoader=true}if(!registeredInModuleLoader){var OldCookies=window.Cookies;var api=window.Cookies=factory();api.noConflict=function(){window.Cookies=OldCookies;return api}}})(function(){function extend(){var i=0;var result={};for(;i<arguments.length;i++){var attributes=arguments[i];for(var key in attributes)result[key]=
attributes[key]}return result}function init(converter){function api(key,value,attributes){var result;if(typeof document==="undefined")return;if(arguments.length>1){attributes=extend({path:"/"},api.defaults,attributes);if(typeof attributes.expires==="number"){var expires=new Date;expires.setMilliseconds(expires.getMilliseconds()+attributes.expires*864E5);attributes.expires=expires}attributes.expires=attributes.expires?attributes.expires.toUTCString():"";try{result=JSON.stringify(value);if(/^[\{\[]/.test(result))value=
result}catch(e){}if(!converter.write)value=encodeURIComponent(String(value)).replace(/%(23|24|26|2B|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g,decodeURIComponent);else value=converter.write(value,key);key=encodeURIComponent(String(key));key=key.replace(/%(23|24|26|2B|5E|60|7C)/g,decodeURIComponent);key=key.replace(/[\(\)]/g,escape);var stringifiedAttributes="";for(var attributeName in attributes){if(!attributes[attributeName])continue;stringifiedAttributes+="; "+attributeName;if(attributes[attributeName]===
true)continue;stringifiedAttributes+="="+attributes[attributeName]}return document.cookie=key+"="+value+stringifiedAttributes}if(!key)result={};var cookies=document.cookie?document.cookie.split("; "):[];var rdecode=/(%[0-9A-Z]{2})+/g;var i=0;for(;i<cookies.length;i++){var parts=cookies[i].split("=");var cookie=parts.slice(1).join("=");if(cookie.charAt(0)==='"')cookie=cookie.slice(1,-1);try{var name=parts[0].replace(rdecode,decodeURIComponent);cookie=converter.read?converter.read(cookie,name):converter(cookie,
name)||cookie.replace(rdecode,decodeURIComponent);if(this.json)try{cookie=JSON.parse(cookie)}catch(e$0){}if(key===name){result=cookie;break}if(!key)result[name]=cookie}catch(e$1){}}return result}api.set=api;api.get=function(key){return api.call(api,key)};api.getJSON=function(){return api.apply({json:true},[].slice.call(arguments))};api.defaults={};api.remove=function(key,attributes){api(key,"",extend(attributes,{expires:-1}))};api.withConverter=init;return api}return init(function(){})});


