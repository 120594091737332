/*--------------------------------------------------------
	Strecke für Kontaktformular
--------------------------------------------------------*/
$(document).ready(function(){
    var data_standort = "";
    var data_kategorie = "";
    $('.ce_rsce_kontaktformular_strecke .ce_rsce_quadrate.standort .quadrat').on('click',function(){
        data_standort = $(this).attr('data-standort');
        $('.ce_rsce_kontaktformular_strecke .step-1').slideUp("slow");
        $('.ce_rsce_kontaktformular_strecke .step-2').slideDown("slow");
    });

    $('.ce_rsce_kontaktformular_strecke .ce_rsce_quadrate.kategorie .quadrat').on('click',function(){
        data_kategorie = $(this).attr('data-kategorie');
        $('.ce_rsce_kontaktformular_strecke .step-2').slideUp("slow");
        $('.ce_rsce_kontaktformular_strecke .step-3').slideDown("slow");
        $('.ce_rsce_kontaktformular_strecke .step-3 form #ctrl_240').val(data_standort);
        $('.ce_rsce_kontaktformular_strecke .step-3 form #ctrl_241').val(data_kategorie);
    });
});