let fahrzeugsuche = document.querySelector('.ce_rsce_fahrzeugSucheAPI');

if (fahrzeugsuche){
    let schnellsuche = fahrzeugsuche.getAttribute('data-schnellsuche');

    if (schnellsuche === 'Ja'){
        document.querySelector('.fahrzeuge').style.display = 'none';
        document.querySelector('.detail-icon').style.display = 'none';

        let suchenButton = fahrzeugsuche.querySelector('#searchButton');

        suchenButton.addEventListener('click', () => {
            let url = window.location.href;
            let currentUrl = new URL(url);
            let params = currentUrl.search;
            let newUrl = 'https://www.auto-roehr.de/fahrzeugsuche.html' + params;
            window.location.href = newUrl;
        })
    }
}