/*--------------------------------------------------------
    maps lazy osvaldas.info
--------------------------------------------------------*/
window.googleMapsScriptLoaded = function() {
	$(window).trigger("googleMapsScriptLoaded");
};
(function(a, p, t, u) {
	var c = a(p),
		q = a("body"),
		h = c.height(),
		k = 0,
		l = function(r, d) {
			var b = null;
			return function() {
				var a = this,
					e = arguments;
				clearTimeout(b);
				b = setTimeout(function() {
					d.apply(a, e);
				}, r);
			};
		},
		m = function(a, d) {
			var b, e;
			return function() {
				var c = this,
					f = arguments,
					g = +new Date();
				b && g < b + a
					? (clearTimeout(e),
					  (e = setTimeout(function() {
							b = g;
							d.apply(c, f);
					  }, a)))
					: ((b = g), d.apply(c, f));
			};
		},
		g = !1,
		n = !1,
		e = a([]),
		f = function(f) {
			k = c.scrollTop();
			e.each(function() {
				var d = a(this),
					b = d.data("options");
				if (d.offset().top - k > 1 * h) return !0;
				g ||
					n ||
					(q.append(
						'<script src="https://maps.googleapis.com/maps/api/js?v=3.exp&callback=googleMapsScriptLoaded' +
							"&key=AIzaSyAmz71RDkeWfd5pbSNaOSJf6czVuET7_HE" +
							'"></script>'
					),
					(n = !0));
				if (!g) return !0;
				var f = new google.maps.Map(this, { zoom: 15 });
				!1 !== b.callback && b.callback(this, f);
				e = e.not(d);
			});
		};
	c.on("googleMapsScriptLoaded", function() {
		g = !0;
		f();
	})
		.on("scroll", m(500, f))
		.on(
			"resize",
			l(1e3, function() {
				h = c.height();
				f();
			})
		);
	a.fn.lazyLoadGoogleMaps = function(c) {
		c = a.extend({ api_key: !1, callback: !1 }, c);
		this.each(function() {
			var d = a(this);
			d.data("options", c);
			e = e.add(d);
		});
		f();
		this.debounce = l;
		this.throttle = m;
		return this;
	};
})(jQuery, window, document);

/*--------------------------------------------------------
Google-Maps-Karte Footer
--------------------------------------------------------*/
$('.map .button').on('click',function(){
    localStorage.setItem('map','show');
    0 < $(".map").length && (function(b, d, e, k) {
        d = b(d);
        var f = [];
        e = b(".map").lazyLoadGoogleMaps({
            callback: function(d, a) {
                var e = b(d),
                lat = $(e).attr('lat'),
                lng = $(e).attr('lng'),
                c = new google.maps.LatLng(lat,lng);
                a.setOptions({
                    zoom: 15,
                    center: c,
                    scrollwheel: 0,
                    linksControl: false,
                    streetViewControl:false,
                    disableDefaultUI: true,
                    zoomControl:true,
                /*	styles:
                    [
                        {
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#f5f5f5"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#616161"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "color": "#f5f5f5"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.land_parcel",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#bdbdbd"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#eeeeee"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#757575"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#e5e5e5"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#757575"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#dadada"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#616161"
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        },
                        {
                            "featureType": "transit.line",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#e5e5e5"
                                }
                            ]
                        },
                        {
                            "featureType": "transit.station",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#eeeeee"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#c9c9c9"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        }
                    ]*/
                });

                var h = new google.maps.Marker({
                    position: c,
                    map: a
                });

                b.data(a, "center", c);
                f.push(a);
                c = function() {
                    b.data(a, "center", a.getCenter());
                };
                google.maps.event.addListener(a, "dragend", c);
                google.maps.event.addListener(a, "zoom_changed", c);
                google.maps.event.addListenerOnce(a, "idle", function() {
                    e.addClass("is-loaded");
                });
            }
        });
        d.on(
            "resize",
            e.debounce(1e3, function() {
                b.each(f, function() {
                    this.setCenter(b.data(this, "center"));
                });
            })
        );
    })(jQuery, window, document);
});

$( document ).ready(function() {
	if(localStorage.getItem('map') == "show") {
        $('.map .button').trigger('click');
    } 
});
