/*--------------------------------------------------------
	Smooth Scrolling
--------------------------------------------------------*/
if($("body.not-smothscroll").length > 0){
} else {
	// Select all links with hashes
	$('a[href*="#"]')
	// Remove links that don't actually link to anything
	.not('[href="#"]')
	.not('[href="#0"]')
	.not('.open-popup-link')
	.click(function(event) {
	// On-page links
	if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
			// Figure out element to scroll to
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
			// Does a scroll target exist?
			if (target.length) {
				// Only prevent default if animation is actually gonna happen
				event.preventDefault();
				$('html, body').animate({
					scrollTop: target.offset().top - ($('#header').height() + 100)
					}, 1000, function() {
					// Callback after animation
					// Must change focus!
					var $target = $(target);
					$target.focus();
					if ($target.is(":focus")) { // Checking if the target was focused
						return false;
					} else {
						$target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
						$target.focus(); // Set focus again
					};
				});
			}
		}
	});
}

/*--------------------------------------------------------
	Smooth Scrolling after reload
--------------------------------------------------------*/
$(document).ready(function() {
	var hash = window.location.hash;	
	if(hash) {
		var timeout = 1000;
		if($('body').hasClass('smothscroll-spaeter')){
			timeout = 2500;
		}
		setTimeout(function(){
			var target = $(hash);
			// Does a scroll target exist?
			if (target.length) {
				$('html,body').animate({scrollTop: $(hash).offset().top - ($('#header').height() + 100)}, 500, 'swing');
			}
		},timeout);
	}
});