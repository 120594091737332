/*--------------------------------------------------------
 SVG Fallback
--------------------------------------------------------*/
if(!Modernizr.svg || $('.ie').length >= 1) {
    $('img[src$="svg"]').attr('src', function() {
        return $(this).attr('src').replace('.svg', '.png');
    });
    $('img[src$="svg"]').attr('src', function() {
        return $(this).attr('src').replace('.svg', '.png');
    });
} 