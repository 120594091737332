// $(document).ready(function() {
// 	setTimeout(function(){
// 		$('.hersteller-connect-popup').addClass('show');
// 	},2000);

// 	setTimeout(function(){
// 		$('.hersteller-connect-popup').removeClass('show');
// 	},10000);

// 	$('.hersteller-connect-popup .close').on('click',function(){
// 		$('.hersteller-connect-popup').removeClass('show');
// 	});
// });



$(document).ready(function() {

	if(localStorage.getItem('popStatusTimestamp')) {
		if((Date.now() - localStorage.getItem('popStatusTimestamp')) >= 180000){
			setTimeout(function(){
				$('.hersteller-connect-popup').addClass('show');
			},2000);
			
			localStorage.setItem('popStatusTimestamp',Date.now());
		}
	} else {
		localStorage.setItem('popStatusTimestamp',Date.now());
	}
	
	if(localStorage.getItem('popStatus') != 'shown'){
		setTimeout(function(){
			$('.hersteller-connect-popup').addClass('show');
		},2000);
		setTimeout(function(){
			$('.hersteller-connect-popup').removeClass('show');
		},10000);
	}
	
	$('.hersteller-connect-popup .close').click(function(e)
	{
		$('.hersteller-connect-popup').removeClass('show');
		localStorage.setItem('popStatus','shown')
	});

});