let markenSelectOptions = [];
let modelleSelectOptions = [];
let karosserieSelectOptions = [];

let fahrzeuge = document.querySelectorAll('.ce_rsce_fahrzeugSucheAPI .fahrzeug');

if (document.querySelector('.ce_rsce_fahrzeugSucheAPI')){
    setFilterInitial();
}

function setFilterInitial() {
    console.log('Initial Filter setzen');

    fahrzeuge.forEach(fahrzeug => {
        let marke = fahrzeug.getAttribute('data-marke');
        let modell = fahrzeug.getAttribute('data-modell');
        let karosserieform = fahrzeug.getAttribute('data-karosserieform');
        let karosserieformen = karosserieform.split(';').map(item => item.trim());
    
        if (!markenSelectOptions.includes(marke) && marke != null) {
            markenSelectOptions.push(marke);
        }
        if (!modelleSelectOptions.includes(modell) && modell != null) {
            modelleSelectOptions.push(modell);
        }

        const excludedTerms = ["PKW", "Unbekannte Kategorie", "Kategorie"];

        karosserieformen.forEach(form => {
            if (form && !karosserieSelectOptions.includes(form)) {
                if (form.includes(' ')) {
                    form = form.slice(form.indexOf(' ') + 1);
                }
                if (!excludedTerms.includes(form)) {
                    karosserieSelectOptions.push(form);
                }
            }
        });

    });

    markenSelectOptions.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
    modelleSelectOptions.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
    karosserieSelectOptions.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
    

    let markenSelect = document.querySelector('.ce_rsce_fahrzeugSucheAPI select[name="marke"]');
    let modelleSelect = document.querySelector('.ce_rsce_fahrzeugSucheAPI select[name="modell"]');
    let karosserieSelect = document.querySelector('.ce_rsce_fahrzeugSucheAPI select[name="karosserieform"]');
    
    function updateSelectOptions(selectElement, options) {
        let existingOptions = Array.from(selectElement.options);
        existingOptions.forEach(option => {
            if (option.value !== "" && !options.includes(option.value)) {
                selectElement.removeChild(option);
            }
        });
        
        let sortedOptions = options.slice().sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
        
        sortedOptions.forEach(option => {
            if (![...selectElement.options].some(opt => opt.value === option)) {
                let opt = document.createElement('option');
                opt.value = option;
                opt.textContent = option;
                
                let inserted = false;
                for (let i = 0; i < selectElement.options.length; i++) {
                    if (selectElement.options[i].value.toLowerCase() > option.toLowerCase()) {
                        selectElement.add(opt, selectElement.options[i]);
                        inserted = true;
                        break;
                    }
                }

                if (!inserted) {
                    selectElement.appendChild(opt);
                }
            }
        });
        
    }

    updateSelectOptions(markenSelect, markenSelectOptions);
    updateSelectOptions(modelleSelect, modelleSelectOptions);
    updateSelectOptions(karosserieSelect, karosserieSelectOptions);

    console.log(markenSelectOptions);
    console.log(modelleSelectOptions);
    console.log(karosserieSelectOptions);
}
