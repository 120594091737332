let filterButtons = document.querySelectorAll('#filters li a');

let ansprechpartner = document.querySelectorAll('.ansprechpartner.grid .grid-item');


if (filterButtons.length > 0){
    filterButtons.forEach(button => {
        button.addEventListener('click', function(){
            event.preventDefault();
            removeActiveFilter();
            button.classList.add('selected');

            let filter = button.getAttribute('data-option-value');
            filterAnsprechpartner(filter);
        })
    })
}

function removeActiveFilter(){
    filterButtons.forEach(button => {
        button.classList.remove('selected');
    })
}

function filterAnsprechpartner(filter){
    console.log(filter);
    if (filter === "Alle"){
        ansprechpartner.forEach(ansprechpartner => {
            ansprechpartner.classList.add('hidden');
            setTimeout(function(){
                ansprechpartner.style.display = 'none';
            },300);
            setTimeout(function(){
                ansprechpartner.classList.remove('hidden');
            },500);
            setTimeout(function(){
                ansprechpartner.style.display = 'block';
            },300);
        });
    } else {
        ansprechpartner.forEach(ansprechpartner => {
            if(ansprechpartner.classList.contains(filter)){
                console.log(ansprechpartner.classList);
                ansprechpartner.classList.add('hidden');
                setTimeout(function(){
                    ansprechpartner.style.display = 'none';
                },300);
                setTimeout(function(){
                    ansprechpartner.classList.remove('hidden');
                },500);
                setTimeout(function(){
                    ansprechpartner.style.display = 'block';
                },300);
            } else {
                ansprechpartner.classList.add('hidden');
                setTimeout(function(){
                    ansprechpartner.style.display = 'none';
                },300);
            }
        });
    }
}
